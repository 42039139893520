#megrendeles{
  background: #f6f6f6;
  //border: 1px solid #e1e1e1;
  position: relative;
  min-height: 200px;
  .nav{
    background: #fff;
  }
  .nav-link{
    text-transform: uppercase;
    font-weight: 700;
    position: relative;

    span{
      position: absolute;
      top: -10px;
      right: 0;
      background: white;
      border-radius: 50%;
      padding: 0 6px;
      color: $brand-primary;
      font-size: 13px;
      border: solid 2px $brand-primary;
    }
  }
}
.tab-content{
  background: #f6f6f6;
  min-height: 200px;
  padding:15px !important;
}
.btn-group{
  display: inline-block !important;
}

#accordion{
  margin-bottom: 15px;

  .close{
    margin-left:15px;
    color: $brand-danger;
  }
}
.card-header {
  padding: 0 !important;
  a{
    display: block;
    padding: $card-spacer-y $card-spacer-x;
    &:hover, &:focus, &:active{
      color: #000;
      text-decoration: none;
    }
  }
}
.check{
  background: $brand-primary;
  padding: 15px;
}

.col-form-label .ar-render{
  font-size:80%;
  color: gray;
}

.btn-white{
  @include button-outline-variant(#fff);
  &:hover:not(:disabled){
    color: $brand-primary;
  }
}
option:disabled {
  color: #d4d4d4 !important;
  text-decoration: line-through;
}
.suc{
  line-height: 50px;
  text-transform: uppercase;
  font-size: 23px;
  font-weight: 700;
  @include media-breakpoint-down(xs) {
    line-height:inherit;
    text-align: center;
  }
  img{
    @include media-breakpoint-up(sm) {
      float: left;
      margin-right: 15px;
    }
    @include media-breakpoint-only(xs) {
      margin: 0 auto;
      display: block;
    }
  }
}