.help-block{
  display: block;
  margin-top: 5px;
  margin-bottom: 10px;
  color: #737373;
}

.fizetendo{
  font-size: 20px;
  margin: 5px 0 0 0;
}
.modal-footer{
  justify-content: space-between;
}
button{
  cursor: pointer;
}

.backdrop{
  background: rgba(255, 255, 255, 0.7);
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 950;
}
.zind{
  z-index: 960 !important;
  position: relative;
 }