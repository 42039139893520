body{
  background: url("../images/body-bg.jpg") #4C3D2A;

  @include media-breakpoint-up(sm){
    background-repeat: no-repeat;
    background-attachment: fixed;
    -webkit-background-size: cover;
    background-size: cover;
  }
  @include media-breakpoint-down(xs){
    background-position: 54% 49%;
  }
}
body.admin{
  background: #fff;
}
* {
  -webkit-transition: all 0.2s ease;
  -moz-transition:    all 0.2s ease;
  -ms-transition:     all 0.2s ease;
  -o-transition:      all 0.2s ease;
}

::-moz-selection { background: $brand-primary; color: #fff;}
::selection { background: $brand-primary; color: #fff; }

.little-title{
  text-transform: uppercase;
  letter-spacing: 2px;
  @include media-breakpoint-down(sm){
    letter-spacing: 0px;
  }
  font-weight: bold;
  color: #fff;
}

.banner{
  .brand .primary-logo{

    width:288px;
    fill: #fff!important;
    display: block;
    text-align: center;
    margin: 0 auto;
    padding:40px;

    @include media-breakpoint-down(sm) {
      margin: 64px auto;
      width: 200px;
      padding:0;

    }


  }
  .call-info{
    @include media-breakpoint-down(sm){
      background: rgba(#000000,0.3);
      //padding: 20px 0 10px;
    }
    @include media-breakpoint-only(md){
      text-shadow: 2px 2px rgba(#000,0.3);
      margin-top: 40px;
    }
    @include media-breakpoint-up(md){
      margin-top: 50px;
    }

  }
  .call-time{
    a{
      color: #ffffff;
    }
    font-size: 16px;

    @extend .little-title;

    @include media-breakpoint-down(sm){
      text-align: center;
      font-size: 14px;
    }
    span{
      display: block;
      font-size: 25px;
      @include media-breakpoint-only(sm){
        font-size: 20px;
      }
      @include media-breakpoint-down(xs){
        font-size: 16px;
      }
    }
    &.mm{
      margin-top: 13px;
    }
  }
}
.wrap{
  background: #fff;
  padding-bottom:15px;
}

.page-header{
  text-transform: uppercase;
  letter-spacing: 2px;
  font-weight: 700;
  color: #fff;

  &::before{
    content: '';
    position: absolute;
    left: 43px;
    width: 38px;
    height: 2px;
    background: $brand-primary;
    margin-left: -19px;
  }

  h1{
    color: #333;
    font-size: 25px;
    padding: 15px;
  }
}

.btn{
  box-shadow: 0 2px 5px 0 rgba(0,0,0,.26);
  outline: none;
  &.button{
    cursor: pointer;
    color: #fff !important;
  }
  &:active{
    box-shadow: 0 4px 8px 0 rgba(0,0,0,.4);
  }
}
.reservation-wrapper{
  background: url('../images/bg-reservation.png') no-repeat;
  width: 286px;

  @include media-breakpoint-up(sm){
    margin: 50px 0 0 15px;
  }
  padding: 35px 40px 77px 40px;
  text-align: center;

  @include media-breakpoint-down(sm){
    background: #1D1D1D;
    width: auto;
    padding: 10px 0;
    margin: 0;
  }
  img{
    height: 70px;
  }
  h1{
    font-size: 17px;
    margin-bottom: 10px;
    margin-top: 21px;
    @extend .little-title;

  }
  h2{
    color: $brand-primary;
    font-weight: 700;
    margin: 40px 0 65px 0;
    @include media-breakpoint-only(xs){
      margin: 38px 0 45px 0;
    }
    @include media-breakpoint-only(xs){
      margin: 15px 0;
    }
    &.mobil{
      color: #fff;
    }
  }
  p{
    color: #cccccc;
    font-size: 15px;
  }
  .btn{
    margin-top: 25px;
  }
}
.all-day {
  margin-top: 30px;
}

.nav-primary{
  padding: 15px 0;
  li{
    position: relative;

    &+li{
      margin-left: 50px;
      :before {

        position: absolute;
        content: '';
        bottom: 15px;
        left: -25px;
        width: 2px;
        height: 17px;
        background: #eee;
      }
    }
    a{
      color: #555;
      font-size: 20px;
      line-height: 21px;

      &:hover{
        text-decoration: none;
        background-color: rgba(238,238,238,.5);
      }
    }
  }
}

.weekly-menu{

  .weekly-item:nth-child(2n){
    background: #F6F6FB;
    span{
      background: #F6F6FB;
    }
  }
  span{
    background: #ffffff;
  }
}
.weekly-item{
  @include clearfix();
  p{
    @include clearfix();
    margin-bottom: 0;
    font-style: italic;
    color: #555;
  }
  padding: 5px 10px;
}
h1.title{
  background: $brand-primary;
  font-size: 18px;
  padding: 10px 15px;
  margin-top: 21px;
  margin-bottom: 10.5px;
  @extend .little-title;
}
.price.leves{
  font-size: 14px;
  font-style: italic;
}
h4{
  border-bottom: 1px dotted;
  line-height: 8px;
  font-size: 17px;
  margin-top: 10.5px;
  margin-bottom: 10.5px;
  color: #000;

  @include media-breakpoint-down(sm){
    line-height: 1.5;
    border-bottom-color: transparent;
  }
  span{
    background: $brand-primary;
    padding-right: 5px;

    &.price{
      padding: 0 0 5px 5px;
    }
  }
}
.fooldali-hetimenu{
  .nav{
    background: #fff;
  }
  .col{
    background: #f6f6fb;
  }
  .nav-link{
    text-transform: uppercase;
    font-weight: 700;
    position: relative;

  }

  .nav-tabs{
    overflow: hidden;
    @include media-breakpoint-down(xs) {
      .disabled{
        display: none;
      }
    }
    border-bottom: 0;
    @include media-breakpoint-up(md) {
      border-right: 2px solid #845c30;
    }
  }
  h4 span{
    background: #f6f6fb;
    padding-right: 5px;
  }
}
.secondary-menu{
  background: #fff;
  width: 100%;
  .navbar-toggler{
    align-self:flex-end;
    border-color: transparent;
    outline: none;
  }

}
.secondary-logo{
  fill: #000;
}

.card-inverse{
  background: #1d1d1d;
  border-color: #1d1d1d;
  -webkit-border-radius:0;
  -moz-border-radius:0;
  border-radius:0;
  margin-top: 21px;
}

.tab-content{
  padding: 15px 0 !important;
}
.footer{
  background: #eaeaea;

  .nav-link{
    color: $gray;
  }
}
.gallery img{
  padding-right: 5px;
  padding-bottom: 10px;
}

.error_page{
  height: 100%;
  body {
    background: $brand-primary !important;
    color: #fff;
    text-align: center;
    height: 100%;
  }
  .wrapper-error_page{
    display: table;
    width: 100%;
    height: 100%;
    min-height: 100%;
  }
  h1{
    font-weight: 900;
    font-size: 150px;
  }
  p{
    text-transform: uppercase;
    font-weight: 900;
    font-size: 25px;
  }
  .fad{
    color: rgba(#fff,0.5);
  }

  .btn{
    -webkit-border-radius:0;
    -moz-border-radius:0;
    border-radius:0;
    display: block;
  }
  .wrapper-inner{
    display: table-cell;
    vertical-align: top;
  }
  @include media-breakpoint-up(sm) {
    .container {
      width: 45rem;
    }
    .wrapper-inner{
      vertical-align: middle;
    }

    p{
      font-size: 45px;
    }
    .btn{
      display: inline;
    }
  }
}
@include media-breakpoint-down(sm) {
.footer{
  .nav, .nav .nav{
    text-align: center;
    display: block;
  }
}
}