$brand-primary: #4c3d2a;
$brand-primary: #845C30;
$font-family-sans-serif: 'Roboto', sans-serif;


/* tabs */
$nav-tabs-border-color: $brand-primary;
$nav-tabs-border-width: 2px;
$nav-tabs-border-radius: 0px;
$nav-link-padding: 12px 24px;

$nav-tabs-link-hover-border-color:            transparent;
$nav-tabs-active-link-hover-color:            #fff;
$nav-tabs-active-link-hover-bg:               $brand-primary;
$nav-tabs-active-link-hover-border-color:     transparent;

/* btn*/

$btn-border-radius: 2px;


/* nav */
$nav-link-padding: 14px 15px;
$nav-disabled-link-color: rgba($brand-primary, 0.5);

$container-max-widths: (
        sm: 640px,
        md: 720px,
        lg: 960px,
        xl: 1000px
) !default;